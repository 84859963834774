.menu-section {
    word-wrap: break-word;
    text-align: center;
    font-size: 22.0pt;
    line-height: 150%;
    font-family: "Monotype Corsiva";
    color: #CC0000;
    text-transform: uppercase;
}

.menu-section-desc {
    font-family: "cambell", serif;
    text-align: center;
}

.menu-p {
    /*margin: 0;*/
    text-align: left;
}

.menu-item {
    margin-top: 5px;
    margin-bottom: 0;
    text-align: left;
    font-size: 14.0pt;
    font-family: "Century Schoolbook", serif;
}

.menu-desc {
    margin: 0;
    padding-right: 10px;
    font-family: "Century Schoolbook", serif;
    font-size: 12.0pt;
}

.menu-price {
    font-family: "cambell", serif;
}

@media (max-width: 768px) {
    .starters-menu-item {
        text-align: center;
    }

    .starters-menu-desc {
        text-align: center;
        font-size: 11.0pt;
    }

    .starters-menu-price {
        text-align: center;
    }

    .starters-column {
        /*flex: 50%;*/
    }

    .starters-row {
        /*display: flex;*/
    }
}

@media (min-width: 769px) {
    .starters-menu-item {
    }

    .starters-menu-desc {
    }

    .starters-column {
        flex: 50%;
    }

    .starters-row {
        display: flex;
    }
}

@media(max-width: 768px) {
    .pizza-column-items-header {

    }

    .pizza-column-items {
        flex: 16.66%;
        margin-right: 15px
    }

    .pizza-row-items {
        overflow: auto;
        white-space: nowrap;
        display: flex;
    }
}

@media(min-width: 769px) {
    .pizza-column-items-header {
        flex: 16.66%;
    }

    .pizza-column-items {
        flex: 16.66%;
    }

    .pizza-row-items {
        display: flex;
    }
}

@media(max-width: 768px) {
    .pizza-mobile {
    }

    .pizza-full {
        display: none;
    }

    .pizza-items-header {
        text-align: center;
    }

    .pizza-items-vertical-scroll {
        height: 100px;
        overflow-y: scroll;
    }

    .pizza-column-types-header {
        flex: 40%;
    }

    .pizza-column-types {
        flex: 20%;
    }

    .pizza-row-types {
        display: flex;
    }
}

@media(min-width: 769px) {
    .pizza-mobile {
        display: none;
    }

    .pizza-full {
    }

    .pizza-column-types-header {
        flex: 25%;
    }

    .pizza-column-types {
        flex: 25%;
    }

    .pizza-row-types {
        display: flex;
    }
}

.pizza-price {
    margin-top: 5px;
    font-size: 13.0pt;
    font-family: "Arial Unicode MS", sans-serif;
    text-align: center;
}

.pizza-size-header {
    margin-top: 5px;
    margin-bottom: 0;
    text-align: center;
    font-size: 14.0pt;
    font-family: "Century Schoolbook", serif;
}

@media(max-width: 768px) {
    .pasta-column {
    }

    .pasta-row {
    }

    .pasta-menu-item {
        text-align: center;
    }

    .pasta-option {
        text-align: center;
        font-size: 12.0pt;
        font-family: "Century Schoolbook", serif;
    }
}

@media(min-width: 769px) {
    .pasta-column {
        flex: 50%;
    }

    .pasta-row {
        display: flex;
    }

    .pasta-menu-item {

    }

    .pasta-option {
        margin-left: 30px;
        text-align: left;
        font-size: 12.0pt;
        font-family: "Century Schoolbook", serif;
    }
}



@media (max-width: 768px) {
    .entrees-menu-item {
        text-align: center;
    }

    .entrees-menu-desc {
        text-align: center;
        font-size: 11.0pt;
    }

    .entrees-menu-price {
        text-align: center;
    }

    .entrees-column {
        /*flex: 50%;*/
    }

    .entrees-row {
        /*display: flex;*/
    }
}

@media (min-width: 769px) {
    .entrees-menu-item {
    }

    .entrees-menu-desc {
    }

    .entrees-menu-price {
    }

    .entrees-column {
        flex: 50%;
    }

    .entrees-row {
        display: flex;
    }
}

@media(max-width: 768px) {
    .kids-menu-mobile {
    }

    .kids-menu-full {
        display: none;
    }

    .kids-menu-item {
        text-align: center;
    }

    .kids-menu-desc {
        text-align: center;
        font-size: 11.0pt;
    }

    .kids-column {
    }

    .kids-row {
    }
}

@media(min-width: 769px) {
    .kids-menu-mobile {
        display: none;
    }

    .kids-menu-full {
    }

    .kids-menu-desc {
        margin-left: 30px;
    }

    .kids-column {
        flex: 50%;
    }

    .kids-row {
        display: flex;
    }
}

@media(max-width: 768px) {
    .sandwiches-mobile {
    }

    .sandwiches-full {
        display: none;
    }

    .sandwiches-menu-item {
        text-align: center;
    }

    .sandwiches-menu-desc {
        text-align: center;
        font-size: 11.0pt;
    }

    .sandwiches-column {
    }

    .sandwiches-row {
    }
}

@media(min-width: 769px) {
    .sandwiches-mobile {
        display: none;
    }

    .sandwiches-full {
    }

    .sandwiches-column {
        flex: 50%;
    }

    .sandwiches-row {
        display: flex;
    }
}

@media(max-width: 768px) {
    .phone-button{
        position: fixed;
        bottom: 30px;
        left: 20px
    }
}

@media(min-width: 769px) {
    .phone-button {
        display: none;
    }
}
