.app-container {
  max-width: none;
  margin: auto;
}

body {
   background-color: transparent;
}

.social-media-button {
  background-color: black;
  border: none;
  padding: 20px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.service-image {
  width: 280px;
  height: 280px;
  border-radius: 50%;
  padding: 10px;
}