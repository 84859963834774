.social-media-image {
    width: 40px;
    height: 40px;
}

.social-media-image-margin {
    margin-left: 10px;
}

.social-media-image-container {
    text-align: center;
}

@media(max-width: 768px) {
    .footer-text {
        font-size: 8pt;
        text-align: center;
    }
}

@media(min-width: 769px) {
    .footer-text {
        margin-top: auto;
        text-align: center;
    }
}