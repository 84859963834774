.catering-item {
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
    font-size: 15.0pt;
    font-family: "Century Schoolbook", serif;
}

.catering-desc {
    margin: 0;
    padding-right: 10px;
    text-align: center;
    font-family: "Century Schoolbook", serif;
    font-size: 11.0pt;
}

.catering-price {
    margin: 0;
    text-align: center;
    font-family: "cambell", serif;
}
